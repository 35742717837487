import * as React from "react"

import Seo from "../../components/seo"
import CustomCompanyInfo from "../../components/CustomCompanyInfo/CustomCompanyInfo"
import {
  LeftAlign,
} from "../../components/Brand/SectionsTemplate"

import {
  legalNoticeData,
} from "./constants"

import BannerImage from "../../assets/images/HeroBannerDTMC.png"

const LegalNotices = ({ pageContext }) => (
  <>
  <Seo title="Legal Notices" lang="en"/> 
  <CustomCompanyInfo
  pageContext={pageContext}
    pageTitle="legal-notices inside-pages"
    headerTitle="Legal Notices"
    bannerImageSrc={BannerImage}
    children={
      <>
        <LeftAlign details={legalNoticeData} />
      </>
    }
  />
  </>
)

export default LegalNotices
